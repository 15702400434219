<template>
  <v-dialog @click:outside="closeDialog" :value="dialog" max-width="450px">
    <v-card style="max-height: 500px; overflow: auto">
      <FilePond
        name="test"
        ref="pond"
        label-idle="Drop files here  or <span class='filepond--label-action'>Browse</span>"
        allow-multiple="true"
        accepted-file-types="image/jpeg, image/png, application/pdf, application/msword, video/mp4, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        v-bind:files="myFiles"
        @addfile="handleAddFile"
        @removefile="handleRemoveFile"
        :instantUpload="false"
        v-bind:required="true"
        @updatefiles="handleFilePondUpdateFile"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click.stop="closeDialog"> close </v-btn>
        <!-- <input type="file" :multiple="multiple" @change="onDrop($event, true)" ref="fileUpload" class="d-none" /> -->
        <v-btn
          small
          :disabled="fileUploadError.length > 0"
          class="ml-2"
          color="primary"
          @click="submit()"
        >
          upload
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);
export default {
  name: "Upload",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    FilePond,
  },
  data() {
    return {
      myServer: {
        url: "url-to-your-api",
      },
      filepondDailog: false,
      myFiles: [],
      dragover: false,
      uploadedFiles: [],
      imageTypes: [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/x-icon",
        "image/svg+xml",
        "image/vnd.microsoft.icon",
      ],
      videoFormates: ["video/mp4", "video/webm"],
      audioFormatees: ["audio/mpeg"],
      zipFormates: [
        "application/vnd.rar",
        "application/x-7z-compressed",
        "application/zip",
      ],
      documentFormates: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
      AllFileTypes: [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/x-icon",
        "image/svg+xml",
        "image/vnd.microsoft.icon",
        "video/mp4",
        "video/webm",
        "application/vnd.rar",
        "application/x-7z-compressed",
        "application/zip",
        "audio/mpeg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
      fileUploadError: [],
      errrorMsg: null,
    };
  },
  methods: {
    closeDialog() {
      // Remove all the uploaded files
      // this.uploadedFiles = [];
      // this.myFiles = [];
      // Close the dialog box
      this.$emit("update:dialog", false);
    },

    clearFiles() {
      this.uploadedFiles = [];
      this.myFiles = [];
      // Close the dialog box
      this.$emit("update:dialog", false);
    },

    handleRemoveFile(error, file) {
      let fileUploadError = this.fileUploadError.filter(
        (item) => item != file.id
      );
      this.fileUploadError = fileUploadError;
    },
    handleAddFile(error, file) {
      if (error != null && Object.keys(error).length > 0) {
        this.fileUploadError.push(file.id);
      }
    },
    handleFilePondUpdateFile(files) {
      this.myFiles = files.map((files) => {
        files.file.id = files.id;
        return files.file;
      });
    },
    submit() {
      if (this.fileUploadError.length > 0) {
        return;
      }
      // If there aren't any files to be uploaded throw error
      if (!this.myFiles.length > 0) {
        this.$store.dispatch("addNotification", {
          message: "There are no files to upload",
          colour: "error",
        });
      } else {
        // Send uploaded files to parent component
        this.$emit("filesUploaded", this.myFiles);
        // Close the dialog box
        // this.closeDialog();
      }
    },
    selectFiles() {
      this.$refs.fileUpload.click();
    },
  },
};
</script>
