<template>
  <v-dialog
    v-model="isOpen"
    :max-width="width"
    @input="$emit('close')"
    persistent
    @keydown.esc="$emit('close')"
    no-click-animation
  >
    <v-card style="border-radius: 14px; max-height: 90vh; overflow-y: auto">
      <v-card-title
        class="headline"
        style="display: flex; justify-content: space-between"
      >
        <span class="my-2">
          {{ title }}
        </span>

        <slot name="closeIcon"></slot>

        <slot></slot>
      </v-card-title>
      <v-divider />
      <div class="pa-5"></div>
      <v-card-actions
        style="display: flex; justify-content: center; gap: 20px"
        v-if="action"
      >
        <div
          style="
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-bottom: 10px;
          "
        >
          <v-btn
            color="secondary"
            width="100px"
            @click.prevent="$emit('close')"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click.prevent="save"
            width="100px"
            :loading="loading"
          >
            {{ submitLabel ? submitLabel : "Save" }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    title: String,
    message: String,
    save: Function,
    action: Boolean,
    submitLabel: String,
    width: {
      type: String,
      default: "600px",
    },
    loading: { type: Boolean, default: false },
  },
  unmounted() {
    this.$emit("close");
  },
};
</script>
