<template>
  <div>
    <div style="margin-top: 2px">
      <v-card tile style="padding-top: 10px" flat class="pl-8 pr-8 pa-5">
        <div
          class="row d-flex align-center justify-content-end"
          style="gap: 5px"
        >
          <div class="border-2 border-radius-6 mr-3">
            <v-autocomplete
              v-model="selectedProject"
              :items="allProjects"
              item-text="name"
              dense
              solo
              flat
              item-value="project_id"
              @change="switchToproject"
              hide-details
              style="font-weight: bold"
            >
            </v-autocomplete>
          </div>
          <v-tabs
            v-model="tab"
            hide-slider
            class="project-tabs mr-5"
            active-class="tab-active"
            background-color="transparent"
          >
            <v-tab
              key="dashboard"
              :href="`#dashboard`"
              @click="handleTasksTabClick('dashboard')"
            >
              <BoardIcon :size="22" class="mr-1" color="currentcolor" />
              Dashboard
            </v-tab>
            <v-tab
              key="board"
              :href="`#board`"
              v-if="displayBoard"
              @click="handleTasksTabClick('board')"
              @dblclick="handleTasksTabClick('list')"
            >
              <BoardIcon :size="22" class="mr-1" color="currentcolor" />
              Board
            </v-tab>
            <v-tab
              key="list"
              :href="`#list`"
              @dblclick="handleTasksTabClick('list')"
              v-else
            >
              <ListIcon :size="22" class="mr-1" color="currentcolor" />
              List
            </v-tab>
            <v-tab
              v-for="projectTab in tabs"
              :key="projectTab.identifier"
              :href="`#${projectTab.identifier}`"
              @click="handleTasksTabClick(projectTab.identifier)"
            >
              <component
                :is="projectTab.icon"
                :size="22"
                class="mr-1"
                color="currentcolor"
              />
              {{ projectTab.name }}
            </v-tab>
            <v-tab
              key="team"
              :href="`#team`"
              @click="handleTasksTabClick('team')"
            >
              <UsersIcon :size="22" class="mr-1" color="currentcolor" />
              Team
            </v-tab>
            <v-tab
              key="status"
              :href="`#status`"
              @click="handleTasksTabClick('status')"
            >
              <UsersIcon :size="22" class="mr-1" color="currentcolor" />
              Status
            </v-tab>
          </v-tabs>

          <div
            class="d-flex align-center"
            v-if="['board', 'list'].includes(tab)"
          >
            <div class="avatar-group">
              <div
                v-for="(assignee, i) in projectTeamMembers"
                :key="assignee.id"
                @click="toggleSelection(assignee.employee?.id)"
                :class="{
                  active: isSelected(assignee.employee?.id),
                  avatar: i < 4,
                }"
                :style="{
                  backgroundColor:
                    i < 4 ? assignee.employee.color : 'transparent',
                }"
              >
                <v-tooltip bottom>
                  <template v-if="i < 4" v-slot:activator="{ on, attrs }">
                    <img
                      v-if="assignee.employee.profile_url"
                      :src="assignee.employee.profile_url"
                      alt="Image"
                      v-bind="attrs"
                      v-on="on"
                    />
                    <div v-bind="attrs" v-on="on" v-else class="fontsize-11">
                      {{ assignee.employee.intials }}
                    </div>
                  </template>
                  <span>{{
                    assignee.employee ? assignee.employee.name : ""
                  }}</span>
                </v-tooltip>
              </div>
              <div style="margin-top: -10px; background-color: transparent">
                <v-menu
                  v-if="projectTeamMembers?.length > 4"
                  offset-y
                  open-on-hover
                  transition="scale-transition"
                  origin="top right"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="asssignee-container mr-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar
                        color="primary"
                        class="white--text avatar-icon cursor-pointer mt-2"
                        size="35"
                      >
                        <span class="fontsize-11"
                          >+{{ projectTeamMembers?.length - 4 }}</span
                        >
                      </v-avatar>
                    </div>
                  </template>
                  <ul class="more-assignees-menu">
                    <template v-for="(moreAssignee, j) in projectTeamMembers">
                      <li
                        v-if="j > 3"
                        :key="j"
                        @click="toggleSelection(moreAssignee.employee?.id)"
                      >
                        <div class="asssignee-container mr-1">
                          <v-avatar
                            :class="{
                              'active-list': isSelected(
                                moreAssignee.employee?.id
                              ),
                            }"
                            color="primary"
                            class="white--text avatar-icon cursor-pointer"
                            size="32"
                          >
                            <img
                              v-if="moreAssignee.employee.profile_url"
                              :src="moreAssignee.employee.profile_url"
                              width="22"
                            />
                            <span v-else class="fontsize-11">{{
                              moreAssignee.employee.intials
                            }}</span>
                          </v-avatar>
                        </div>
                        <span>{{ moreAssignee.employee.name }}</span>
                      </li>
                    </template>
                  </ul>
                </v-menu>
              </div>

              <div class="mr-3 ml-3 mt-1">
                <v-menu
                  offset-y
                  nudge-left="100"
                  nudge-bottom="10"
                  nudge-width="500"
                  :close-on-content-click="false"
                  max-width="500"
                  v-if="['board', 'list'].includes(tab)"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cursor-pointer" v-bind="attrs" v-on="on">
                      <v-badge
                        bordered
                        color="red"
                        :content="selectedTeamMembers?.length"
                        :value="selectedTeamMembers?.length"
                        overlap
                      >
                        <v-btn
                          fab
                          depressed
                          color="white"
                          small
                          class="primary"
                        >
                          <v-icon> mdi mdi-filter-outline </v-icon>
                        </v-btn>
                      </v-badge>
                    </div>
                  </template>
                  <div class="white">
                    <v-text-field
                      v-model="searchTeamMember"
                      solo
                      flat
                      placeholder="Search..."
                      dense
                      hide-details=""
                      class="py-1 border-1"
                      prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                    <v-list style="max-height: 300px; overflow-y: scroll">
                      <v-list-item
                        v-for="teamMember in filteredTeamMembers"
                        :key="teamMember.id"
                      >
                        <v-list-item-avatar>
                          <label
                            :for="`team_member_${teamMember.employee?.id}`"
                          >
                            <Avatar
                              size="35"
                              :color="teamMember.employee?.color"
                              :intials="teamMember.employee?.intials"
                              :profile_url="teamMember.employee?.profile_url"
                            />
                          </label>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <label
                            :for="`team_member_${teamMember.employee?.id}`"
                            class="cursor-pointer"
                          >
                            <v-list-item-title
                              class="text-truncate"
                              :title="teamMember.employee?.name || 'User'"
                            >
                              {{ teamMember.employee?.name || "User" }}
                            </v-list-item-title>
                          </label>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="selectedTeamMembers"
                            :value="teamMember.employee?.id"
                            multiple
                            :id="`team_member_${teamMember.employee?.id}`"
                            :name="`team_member_${teamMember.employee?.id}`"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="selectedTeamMembers?.length">
                        <v-list-item-action
                          @click="selectedTeamMembers = []"
                          style="cursor: pointer; color: red"
                        >
                          All clear
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <p
                      v-if="!filteredTeamMembers?.length"
                      class="secondary--text subtitle-2 text-center"
                    >
                      No Members found
                    </p>
                  </div>
                  <div class="white">
                    <v-list>
                      <div style="padding: 0px 0px 0px 15px; font-weight: bold">
                        Priority
                      </div>
                      <v-list-item class="mt-2">
                        <v-select
                          outlined
                          flat
                          solo
                          v-model="selectedPriorities"
                          :items="priorities"
                          item-text="name"
                          item-value="id"
                          label="Select Priority"
                          multiple
                          chips
                          clearable
                          hide-details="true"
                        ></v-select>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div class="white">
                    <v-list>
                      <div style="padding: 0px 0px 0px 15px; font-weight: bold">
                        Tags
                      </div>
                      <v-list-item class="mt-2">
                        <v-select
                          outlined
                          flat
                          solo
                          v-model="selectedTags"
                          :items="projectTags"
                          item-text="name"
                          item-value="id"
                          label="Select Tags"
                          multiple
                          chips
                          clearable
                        ></v-select>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div
                    style="padding: 0px 0px 10px 15px; font-weight: bold"
                    class="white"
                  >
                    <v-list>
                      <v-btn @click="refreshTasks" color="primary">Apply</v-btn>
                      <v-btn @click="clearFilter" color="error" class="ml-3"
                        >clear</v-btn
                      >
                    </v-list>
                  </div>
                </v-menu>
              </div>
            </div>
          </div>

          <div class="d-flex align-center border-2 border-radius-6 mr-3">
            <v-select
              v-model="list_id"
              :items="projectsLists"
              item-text="name"
              item-value="list_id"
              placeholder="Select List"
              class="d-inline-block custom-data-table-select-solo"
              dense
              solo
              flat
              style="max-width: 200px; font-size: 14px"
              @input="updateListCash"
            />
            <v-icon
              size="20px"
              color="secondary"
              class="px-2 edit-group-icon"
              @click="settingListDialog = true"
            >
              mdi-cog-outline
            </v-icon>

            <v-icon
              size="20px"
              color="white"
              class="px-2 edit-group-icon primary border-radius-6"
              @click="openAddEditListDialog"
            >
              mdi-plus
            </v-icon>
          </div>
          <div class="d-flex align-center justify-content-end" style="gap: 5px">
            <div class="border-2 border-radius-6">
              <v-text-field
                v-model="search"
                @input="handleDebouncedValue"
                solo
                flat
                placeholder="Search..."
                dense
                hide-details=""
                prepend-inner-icon="mdi-magnify"
                v-show="['board', 'list'].includes(tab)"
              ></v-text-field>
            </div>
            <v-btn
              color="secondary"
              depressed
              class="px-3 border-radius-6 custom-data-table-action-btn"
              @click="openAddEditTaskDialog()"
            >
              <v-icon class="mr-1"> mdi-plus </v-icon>
              Add Task
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <div class="mt-2 px-0">
      <v-card tile class="pb-3" flat>
        <v-card-text class="py-0 my-0" :class="displayBoard ? 'px-0' : 'px-4'">
          <template v-if="showBoard">
            <Board
              v-if="displayBoard"
              :list_id="list_id"
              :project_id="project_id"
              ref="kanbanComponent"
              :search="debouncedSearch"
              @openTaskDetailsDialog="openTaskDetailsDialog"
              :selectedTeamMembers="selectedTeamMembers"
              :selectedPriorities="selectedPriorities"
              :selectedTags="selectedTags"
              @openAddEditTaskDialog="openAddEditTaskDialog"
              @deleteTask="showDeleteTaskConfirmationDialog"
              :reorderPopupOpenClose="reorderPopupOpenClose"
              @getListStatuses="getListStatuses"
            />
            <TasksByStatus
              v-else
              :list_id="list_id"
              :search="debouncedSearch"
              :selectedTeamMembers="selectedTeamMembers"
              :selectedPriorities="selectedPriorities"
              :selectedTags="selectedTags"
              @openAddEditTaskDialog="openAddEditTaskDialog"
              @openTaskDetailsDialog="openTaskDetailsDialog"
              @deleteTask="showDeleteTaskConfirmationDialog"
              ref="tasksByStatusComponent"
              :reorderPopupOpenClose="reorderPopupOpenClose"
            />
          </template>
          <keep-alive>
            <component
              v-bind:is="tabComponent"
              :name="tabComponent"
              :project_id="project_id"
              :selected="selected"
              :project="project"
              :members="projectTeamMembers"
              @refreshTeamMembersList="getProjectTeamMembers"
              @getListStatuses="getListStatuses"
            />
          </keep-alive>
        </v-card-text>
        <v-overlay absolute :value="isErrorOccured" color="white" opacity="1">
          <div class="text-center">
            <p class="primary--text" style="font-size: 50px; margin: 0px">
              Oops !
            </p>
            <p class="grey--text subtitle-1">{{ errorMessage }}</p>
            <v-btn
              color="primary"
              class="mt-3"
              rounded
              depressed
              @click="$router.push(`/projects/`)"
              ><v-icon size="18" class="mr-3">mdi-arrow-left</v-icon>Go
              Back</v-btn
            >
          </div>
        </v-overlay>
      </v-card>

      <v-snackbar
        v-model="alert.show"
        :timeout="3000"
        :color="alert.color"
        align-baseline="center"
      >
        {{ alert.message }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="alert.show = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <AddEditTask
        v-model="addEditTaskDialog"
        :taskDetails="editedItem"
        :isEditingTask="isEditingTask"
        @taskSaved="handleTaskSave"
        @close="closeAddEditTaskDialog"
      />

      <TaskDetail
        v-if="selectedTaskId"
        :blockTaskId="selectedTaskId"
        :taskDetailsDialog="showTaskDetailsDialog"
        :selectedlistId="selectedlistId"
        :isUpdate="true"
        :project_id="project_id"
        @closeTaskDetailModel="closeTaskDetailsDialog"
        :redirect="false"
      />

      <Dialog
        :isOpen="settingListDialog"
        width="350"
        @close="closeSettingListDialog"
        title="Project Group List"
      >
        <template>
          <div class="mt-5">
            <v-row v-for="item in projectsLists" :key="item.list_id">
              <v-col cols="8" class="pb-0">
                <span>{{ item.name }}</span>
              </v-col>
              <v-col cols="4" class="pb-0 pr-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      x-small
                      style="margin-left: 10px"
                      :color="item.is_default == 1 ? 'primary' : ''"
                      v-on="on"
                      @click="setDefaultList(item.list_id)"
                    >
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Set Default</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      x-small
                      style="margin-left: 10px"
                      v-on="on"
                      @click="editList(item.list_id)"
                    >
                      <PencilIcon :size="19" />
                    </v-btn>
                  </template>
                  <span>Edit List</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      x-small
                      style="margin-left: 10px"
                      v-on="on"
                      @click="deleteList(item.list_id)"
                    >
                      <TrashIcon :size="19" />
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-btn
                color="secondary"
                width="100px"
                class="mt-10"
                @click.prevent="settingListDialog = false"
              >
                Close
              </v-btn>
            </v-row>
          </div>
        </template>
      </Dialog>

      <Dialog
        :isOpen="showAddEditListDialog"
        width="350"
        :save="editListId ? updateListDetails : addListDetails"
        @close="closeAddEditListDialog"
        :action="true"
        :title="editListId ? `Edit List` : `Add List`"
        :loading="isSavingListDetails"
      >
        <template>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Name"
                name="name"
                v-model="listName"
                :error="errors.listName != null"
                :error-messages="errors.listName"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0" v-if="!editListId">
              <v-select
                v-model="selectedFolderId"
                label="Folder"
                :items="projectFolders"
                :loading="isLoadingProjectFolders"
                item-text="name"
                item-value="id"
                outlined
                dense
              >
              </v-select>
            </v-col>
          </v-row>
        </template>
      </Dialog>

      <v-dialog v-model="showDeleteTaskDialog" max-width="400px">
        <v-card>
          <v-card-title class="subtitle-1"
            >Are you sure you want to delete this Task?</v-card-title
          >
          <v-card-text class="body-2 text--grey darken-4">
            Any <b>Attachments</b> and <b>Subtasks</b> of this task will also be
            deleted
          </v-card-text>
          <v-card-actions class="mr-2">
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              class="capitalize"
              @click="closeDeleteTaskConfirmationDialog"
              :disabled="isDeletingTask"
              >Cancel</v-btn
            >
            <v-btn
              color="red"
              text
              class="capitalize"
              @click="deleteTask"
              :loading="isDeletingTask"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import InfoIcon from "../../assets/icons/InfoIcon.vue";
import BoardIcon from "../../assets/icons/BoardIcon.vue";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon.vue";
import UsersIcon from "../../assets/icons/UsersIcon.vue";
import FunnelIcon from "../../assets/icons/FunnelIcon.vue";
import Team from "../projects/Team";
import Planning from "../projects/Planning";
import TasksByStatus from "../projects/view/TasksByStatus.vue";
import { debounce } from "lodash";
import Avatar from "../common/basic/Avatar.vue";
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import AddEditTask from "../common/task/AddEditTask.vue";
import TaskDetail from "../projects/TaskDetail.vue";
import Drawer from "../common/basic/Drawer.vue";
import Board from "./view/Board.vue";
import ListIcon from "../../assets/icons/ListIcon.vue";
import { cloneDeep } from "lodash";
import PencilIcon from "@/assets/icons/PencilIcon.vue";
import TrashIcon from "@/assets/icons/TrashIcon.vue";
import ReArrangeStatusOrder from "./view/ReArrangeStatusOrder.vue";
import Dialog from "../common/basic/Dialog.vue";
import Dashboard from "../../components/projects/Dashboard.vue";

const defaultEditedItemValue = {
  name: "",
  list_id: "",
  description: "",
  attachments: [],
  assignees: [],
  task_id: "",
  status: "",
  task_priority: "",
};

const defaultListActiveStatuses = [
  {
    tag: "OPEN",
    color: "#d3d3d3",
  },
  {
    tag: "IN PROGRESS",
    color: "#4194f6",
  },
  {
    tag: "REVIEW",
    color: "#a875ff",
  },
];

const defaultListClosedStatuses = [
  {
    tag: "CLOSED",
    color: "#6bc950",
  },
];

const intialData = {
  project_id: "",
  userRole: "",
  project: {
    name: "",
    start_date: "",
    end_date: "",
    estimated_time_for_qa: 0,
    estimated_time_for_developement: 0,
  },
  selected: [],
  settingListDialog: false,
  loading: true,
  isErrorOccured: false,
  errorMessage: "",
  snackbar: false,
  snackbarError: false,
  message: "sddsd",
  search: "",
  debouncedSearch: "",
  tab: null,
  tabs: [
    {
      identifier: "planning",
      icon: "CalendarBlankIcon",
      name: "Planning",
    },
  ],
  selectedGroupList: {},
  list_id: null,
  selectedProject: {},
  switchProject: null,
  isLoadingTeamMembers: false,
  searchTeamMember: "",
  selectedTeamMembers: [],
  selectedPriorities: [],
  selectedTags: [],

  projectsLists: [],
  isLoadingProjectLists: false,
  addEditTaskDialog: false,
  isEditingTask: false,
  editedItem: defaultEditedItemValue,
  errors: {},
  showDeleteTaskDialog: false,
  deleteTaskId: null,
  isDeletingTask: false,
  alert: {
    show: false,
    message: "",
    color: "",
  },
  selectedTaskId: null,
  selectedlistId: null,
  showTaskDetailsDialog: false,
  isLoadingListStatuses: false,
  showAddEditListDialog: false,
  isSavingListDetails: false,
  listName: "",
  editListId: null,
  selectedFolderId: null,
  isLoadingProjectFolders: false,
  projectFolders: [],
  displayBoard: true,
  showTeamMembers: false,
  reorderPopupOpenClose: false,
  reorderTaskStatusDialog: false,
  tasksTab: "",
};

export default {
  name: "Project",
  data: () => {
    return cloneDeep(intialData);
  },
  components: {
    InfoIcon,
    BoardIcon,
    ListIcon,
    CalendarBlankIcon,
    UsersIcon,
    FunnelIcon,
    Team,
    Planning,
    TasksByStatus,
    Avatar,
    AddEditTask,
    Drawer,
    TaskDetail,
    Board,
    PencilIcon,
    TrashIcon,
    Dialog,
    ReArrangeStatusOrder,
    Dashboard,
  },
  watch: {
    project_id() {
      this.selectedProject = this.allProjects.find(
        (item) => item.project_id == this.project_id
      );
    },
    list_id: function (newId) {
      if (newId) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              list_id: newId,
            },
          })
          .catch(() => {});
      }

      // this.setSelectedListStatuses([]);
      this.setSelectedProjectListId(newId || null);
      this.getListStatuses();
    },

    $route: function (route) {
      const params = route.params;
      const query = route.query;
      console.log(
        "this.$route.params.active_tab",
        this.$route.params.active_tab
      );

      if (this.$route.params.active_tab) {
        this.tab = this.$route.params.active_tab;
      }
      if (
        params &&
        route.name === "projectDetails" &&
        params.id != this.project_id
      ) {
        Object.assign(this.$data, cloneDeep(intialData));
        this.project_id = params.id;
        this.getProjectDetails();
        this.getProjectLists();
        this.getProjectFolders();
        this.getProjectTags();
      }

      if (query) {
        if (query.list_id) {
          let id = Number(query.list_id);

          if (!isNaN(id) && id != this.list_id) {
            this.list_id = id;
          }
        }

        if (query.task_id) {
          let id = Number(query.task_id);
          let listId = Number(this.$route.query.list_id);

          if (!isNaN(id)) {
            this.openTaskDetailsDialog(id, listId);
          }

          delete query.task_id;

          this.$router
            .replace({
              query: {
                ...query,
              },
            })
            .catch(() => {});
        }
      }
    },
  },
  computed: {
    ...mapState("tasks", ["priorities"]),
    ...mapState("projects", [
      "allProjects",
      "projectTeamMembers",
      "selectedListStatuses",
      "cachesTeamMembers",
      "cachesStatusesList",
      "projectTags",
      "cachesTags",
    ]),
    showBoard: function () {
      return !["planning", "team", "status", "dashboard"].includes(this.tab);
    },
    filteredTeamMembers() {
      if (this.searchTeamMember) {
        const regExp = new RegExp(this.searchTeamMember.toLowerCase(), "ig");

        return this.projectTeamMembers.filter((member) => {
          if (member.employee && member.employee.name) {
            return member.employee.name.toLowerCase().search(regExp) != -1;
          }

          return false;
        });
      } else {
        return this.projectTeamMembers;
      }
    },
    startTime() {
      const startDate = this.project?.start_date;

      return startDate ? this.humanReadableDate(startDate) : "N/A";
    },
    qaHours() {
      const estimatedHours = this.project?.estimated_time_for_qa;

      return estimatedHours == 0 ? "Not Set" : estimatedHours;
    },
    estimatedDevelopmentTime() {
      const estimatedDevTime = this.project?.estimated_time_for_developement;

      return estimatedDevTime == 0 ? "Not Set" : estimatedDevTime;
    },
    tabComponent() {
      let componentName;
      if (this.tab == "board") {
        componentName = "Board";
      } else if (this.tab == "team") {
        componentName = "Team";
      } else if (this.tab == "planning") {
        componentName = "Planning";
      } else if (this.tab == "list") {
        componentName = "List";
      } else if (this.tab == "status") {
        componentName = "ReArrangeStatusOrder";
      } else if (this.tab == "dashboard") {
        componentName = "Dashboard";
      } else {
        componentName = "Board";
      }
      return componentName;
    },
  },
  methods: {
    ...mapMutations("projects", [
      "setProjectTeamMembers",
      "setProjectData",
      "setProjectLists",
      "setSelectedProjectListId",
      "setSelectedListStatuses",
      "updateProjectCaches",
      "setProjectTags",
    ]),
    toggleSelection(employeeId) {
      const index = this.selectedTeamMembers.indexOf(employeeId);
      if (index > -1) {
        // Remove employee if already selected
        this.selectedTeamMembers.splice(index, 1);
      } else {
        // Add employee to the selection
        this.selectedTeamMembers = [employeeId];
      }
      setTimeout(() => {
        this.refreshTasks();
      }, 100);
    },
    isSelected(employeeId) {
      return this.selectedTeamMembers.includes(employeeId);
    },
    showAlertMessage(isSucessMsg, message) {
      this.alert = {
        message: message || "",
        color: isSucessMsg ? "green accent-4" : "red accent-2",
        show: true,
      };
    },
    closeSettingListDialog() {
      this.settingListDialog = false;
    },
    tabChange(tab) {
      this.$router
        .replace({
          params: {
            ...this.$route.params,
            active_tab: tab,
          },
        })
        .catch(() => {});
    },
    getProjectDetails() {
      let _self = this;

      this.$axios.get("/project/" + _self.project_id).then((res) => {
        let data = res.data;
        if (!data.success) {
          this.isErrorOccured = true;
          this.errorMessage = data.message;
        } else {
          _self.project = data.project;
          _self.selected = data.members;
        }
      });
    },
    handleDebouncedValue: debounce(function () {
      this.debouncedSearch = this.search;
    }, 300),

    getProjectTeamMembers() {
      if (this.cachesTeamMembers) {
        return;
      }

      this.isLoadingTeamMembers = true;

      this.$axios
        .post(`/getmembers?project_id=${this.project_id}`)
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            resData.members = resData.members.map((member) => {
              member.isSelected = false;
              return member;
            });

            this.setProjectTeamMembers(resData.members);
            this.updateProjectCaches({ key: "cachesTeamMembers", value: true });
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isLoadingTeamMembers = false;
        });
    },
    getProjectLists() {
      this.isLoadingProjectLists = true;

      this.$axios
        .get(`/projects/${this.project_id}/lists`)
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            this.projectsLists = resData.lists;

            // set intial selected list
            if (!this.list_id && resData.lists?.length) {
              this.list_id =
                resData.defaultList &&
                Object.keys(resData.defaultList)?.length > 0
                  ? resData.defaultList.list_id
                  : resData.lists[0]?.list_id;
              this.setSelectedProjectListId(this.list_id);
            }

            this.setProjectLists(resData.lists);
          } else {
            this.isErrorOccured = true;
            this.errorMessage = resData.message;
            // this.showAlertMessage(
            //   false,
            //   resData.message || "Something went wrong"
            // );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isLoadingProjectLists = false;
        });
    },
    async getAllProjects() {
      this.loading = true;
      if (this.userRole == "admin") {
        this.$axios.get("project").then((res) => {
          this.setProjectData(res.data.projects);
        });
      } else {
        this.$axios.get("employee/project").then((res) => {
          this.setProjectData(res.data.projects);
        });
      }
      this.loading = false;
    },
    switchToproject($e) {
      this.updateProjectCaches({ key: "cachesTeamMembers", value: false });
      this.updateProjectCaches({ key: "cachesStatusesList", value: false });
      this.updateProjectCaches({ key: "cachesTags", value: false });

      if ($e != null) {
        this.$router.push({
          path: `/projects/${$e}/${this.tab}`,
          params: { ...this.$router.params, active_tab: this.tab },
        });
      }
    },
    getProjectTags() {
      if (this.cachesTags || !this.project_id) {
        return;
      }
      let _self = this;
      _self.$axios
        .get("get_project_tags/" + this.project_id)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.setProjectTags(data.tags);
            this.updateProjectCaches({ key: "cachesTags", value: true });
          } else {
            this.error = true;
            this.message = data.message;
          }
        });
    },
    updateListCash() {
      if (this.tasksTab === "status") {
        this.updateProjectCaches({
          key: "cachesStatusesList",
          value: false,
        });
      }
    },
    getListStatuses() {
      if (this.cachesStatusesList || !this.list_id) {
        return;
      }
      this.isLoadingListStatuses = true;

      this.$axios
        .get(`/list/${this.list_id}/statuses`)
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            this.setSelectedListStatuses(resData.data);
            this.updateProjectCaches({
              key: "cachesStatusesList",
              value: true,
            });
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isLoadingListStatuses = false;
        });
    },
    humanReadableDate(date) {
      return moment(date).format("Do MMMM YYYY");
    },

    closeReorderTaskStatusDialog() {
      this.reorderTaskStatusDialog = false;
    },
    openAddEditTaskDialog(task, statusId = null) {
      this.addEditTaskDialog = true;
      this.isEditingTask = task ? true : false;
      this.errors = {};

      if (task) {
        this.editedItem = {
          task_id: task.task_id,
          name: task.name,
          description: task.description,
          list_id: task.list_id,
          status_id: task.status_id,
          task_priority: task.task_priority,
          assignees: task.assignees.map((assignee) => {
            return assignee.employee_id;
          }),
        };
      } else {
        let defaultStatusId = null;

        if (this.selectedListStatuses?.length) {
          defaultStatusId = this.selectedListStatuses[0].id;
        }

        this.editedItem = {
          ...defaultEditedItemValue,
          list_id: this.list_id || "",
          status_id: statusId || defaultStatusId || "",
        };

        console.log(this.editedItem, "this.editedItem");
      }
    },
    closeAddEditTaskDialog() {
      this.addEditTaskDialog = false;
      this.errors = {};
      this.editedItem = defaultEditedItemValue;
    },

    async clearFilter() {
      this.selectedTags = [];
      this.selectedTeamMembers = [];
      this.selectedPriorities = [];
      setTimeout(() => {
        this.refreshTasks();
      }, 100);
    },
    refreshTasks() {
      // refresh task list
      if (this.tab == "list") {
        if (this.$refs.tasksByStatusComponent) {
          this.$refs.tasksByStatusComponent.getListTasksByStatus();
        }
      } else {
        const kanbanComponent = this.$refs.kanbanComponent;

        if (kanbanComponent) {
          kanbanComponent.getListTasksByStatus();
        }
      }
    },
    handleTaskSave() {
      this.closeAddEditTaskDialog();

      setTimeout(() => {
        this.refreshTasks();
      }, 300);
    },
    showDeleteTaskConfirmationDialog(taskId) {
      this.showDeleteTaskDialog = true;
      this.deleteTaskId = taskId;
    },
    closeDeleteTaskConfirmationDialog() {
      this.deleteTaskId = null;
      this.showDeleteTaskDialog = false;
    },
    deleteTask() {
      if (!this.deleteTaskId) {
        console.log("Could not delete task, Task id not found");
        return;
      }

      this.isDeletingTask = true;

      let requestStartTime = new Date().getTime();

      this.$axios
        .delete(`tasks/${this.deleteTaskId}`)
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            this.showAlertMessage(true, resData.message);
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          let requestEndTime = new Date().getTime();
          let totalTimeElapsed = requestEndTime - requestStartTime;

          if (totalTimeElapsed > 200) {
            this.isDeletingTask = false;
            this.closeDeleteTaskConfirmationDialog();
            this.refreshTasks();
          } else {
            setTimeout(() => {
              this.isDeletingTask = false;
              this.closeDeleteTaskConfirmationDialog();
              this.refreshTasks();
            }, 200);
          }
        });
    },
    openTaskDetailsDialog(taskId, listId) {
      this.selectedTaskId = taskId;
      this.selectedlistId = listId;
      this.showTaskDetailsDialog = true;
    },
    closeTaskDetailsDialog() {
      this.showTaskDetailsDialog = false;
      this.selectedTaskId = null;
      this.refreshTasks();
    },
    openAddEditListDialog(list) {
      if (list) {
        this.editListId = list.list_id;
        this.listName = list.name;
      } else {
        this.listName = "";
        this.editListId = null;
      }

      this.showAddEditListDialog = true;
    },
    closeAddEditListDialog() {
      this.showAddEditListDialog = false;
      this.listName = "";
      this.editListId = null;
      this.errors = {};
    },
    validateListDetails() {
      if (!this.listName) {
        this.errors = {
          listName: "list name is required",
        };

        return false;
      }

      return true;
    },
    addListDetails() {
      const isDetailsValid = this.validateListDetails();

      if (!isDetailsValid) {
        return;
      }

      const reqData = {
        project_id: this.project_id,
        folder_id: this.selectedFolderId,
        name: this.listName,
        activeStatuses: defaultListActiveStatuses,
        closedStatus: defaultListClosedStatuses,
      };

      this.isSavingListDetails = true;

      this.$axios
        .post("list", reqData)
        .then((response) => {
          const resData = response.data;

          if (resData.status) {
            this.showAlertMessage(true, resData.message);
            this.getProjectLists();
            this.closeAddEditListDialog();
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isSavingListDetails = false;
        });
    },
    updateListDetails() {
      this.isSavingListDetails = true;

      let reqData = {
        project_id: this.project_id,
        name: this.listName,
      };

      this.$axios
        .put(`list/${this.editListId}`, reqData)
        .then((response) => {
          const resData = response.data;

          if (resData.status) {
            let listIndex = this.projectsLists.findIndex(
              (list) => list.list_id == this.editListId
            );

            if (listIndex != -1) {
              let tempLists = [...this.projectsLists];

              tempLists[listIndex] = {
                ...tempLists[listIndex],
                name: this.listName,
              };

              this.projectsLists = tempLists;
            }

            this.showAlertMessage(true, resData.message);
            this.closeAddEditListDialog();
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isSavingListDetails = false;
        });
    },
    getProjectFolders() {
      this.isLoadingProjectFolders = true;

      this.$axios
        .get(`project/${this.project_id}/folders?include_list_details=false`)
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            this.projectFolders = resData.folders;

            if (resData.folders?.length) {
              this.selectedFolderId = resData.folders[0].id;
            }
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isLoadingProjectFolders = false;
        });
    },

    setDefaultList(listId) {
      this.isSavingListDetails = true;
      this.$axios
        .post(`set_default_list/${this.project_id}`, { list_id: listId })
        .then((response) => {
          const resData = response.data;

          if (resData.status) {
            this.getProjectLists();
            this.showAlertMessage(true, resData.message);
            // this.getProjectLists();
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isSavingListDetails = false;
        });
    },
    editList(listId) {
      let list = this.projectsLists.find((list) => list.list_id == listId);

      if (list) {
        this.openAddEditListDialog(list);
      }
    },
    deleteList(listId) {
      this.isSavingListDetails = true;

      this.$axios
        .delete(`list/${listId}`)
        .then((response) => {
          const resData = response.data;

          if (resData.status) {
            this.showAlertMessage(true, resData.message);
            this.getProjectLists();
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isSavingListDetails = false;
        });
    },
    handleTasksTabClick(tab) {
      this.tasksTab = tab;
      this.tabChange(tab);
      if (!["planning", "team", "dashboard", "status", "board"].includes(tab)) {
        this.displayBoard = !this.displayBoard;
      }
    },
    openTeamMembersDialog() {
      this.showTeamMembers = true;
    },
    closeTeamMembersDialog() {
      this.showTeamMembers = false;
    },
  },
  async mounted() {
    this.tasksTab = this.$route.params.active_tab;
    this.project_id = this.$route.params.id;
    console.log("this.tasksTab", this.tasksTab);

    // setting list id from url if exist
    if (this.$route.query.list_id) {
      let id = Number(this.$route.query.list_id);

      if (!isNaN(id)) {
        this.list_id = id;
      }
    }
    let userData = JSON.parse(localStorage.getItem("user"));
    this.userRole = userData.role;

    if (this.allProjects?.length == 0) {
      await this.getAllProjects().then(() => {
        this.selectedProject = this.allProjects.find(
          (item) => item.project_id == this.project_id
        );
      });
    }

    // set the current tab from url if exist
    const activateTab = this.$route.params.active_tab || "board";
    if (
      activateTab &&
      !["list", "board", "planning", "team", "status", "dashboard"].includes(
        activateTab
      )
    ) {
      this.tab = "board";
    } else {
      this.tab = this.$route.params.active_tab;
    }

    // open task dialog if task_id exist in url
    if (this.$route.query.task_id) {
      let id = Number(this.$route.query.task_id);
      let listId = Number(this.$route.query.list_id);

      if (!isNaN(id)) {
        this.openTaskDetailsDialog(id, listId);
      }
    }

    setTimeout(() => {
      this.selectedProject = this.allProjects.find(
        (item) => item.project_id == this.project_id
      );
    }, 500);
    this.updateProjectCaches({ key: "cachesTeamMembers", value: false });
    this.getProjectDetails();
    this.getProjectTeamMembers();
    this.getProjectLists();
    this.getProjectFolders();
    this.getListStatuses();
    // this.switchProject = this.project_id;
  },

  beforeDestroy() {
    this.setSelectedProjectListId(null);
    this.setProjectLists([]);
    this.setSelectedListStatuses([]);
  },
};
</script>

<style lang="scss" scoped>
.project-tabs {
  width: fit-content;
  display: inline-block;

  .v-tab {
    text-transform: capitalize;
    letter-spacing: 0ch;
    height: 36px !important;
    border-radius: 4px !important;
  }
  .v-tabs-bar {
    height: 36px !important;
  }
}

.dummy-tab {
  text-transform: capitalize;
  letter-spacing: 0ch;
  color: #888888;
}

.project-info-container {
  .title {
    font-size: 15px !important;
    color: black;
    border-bottom: 1px solid var(--border-color);
    padding: 10px 20px;
  }

  .content {
    padding: 15px 20px;
  }

  .content-label {
    font-size: 14px;
    color: black;
    font-weight: bold;
  }

  .content-value {
    margin-left: 10px;
    font-size: 14px;
    color: #787486;
  }
}

.edit-group-icon {
  height: 39px;
  border-left: 2px solid var(--border-color);
  background: #f0f4f8;
}

.tab-active {
  background-color: #269fd7 !important;
  border-radius: 4px !important;
  color: white !important;
}
.v-btn--fab.v-size--small {
  height: 36px !important;
  width: 36px !important;
}
.custom_height {
  min-height: 56px !important;
}
@media screen and (max-width: 1671px) {
  .custom_height {
    min-height: 1000px;
  }
}

.avatar-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ccc;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  transition: 0.2s;
  border: 3px solid #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}

.avatar:not(:first-child) {
  margin-left: -0.6rem !important;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.avatar.active {
  box-shadow: inset 0 0 0 1px white, 0 0 0 3px blue;
}

.active-list {
  box-shadow: inset 0 0 0 1px white, 0 0 0 3px blue;
}
.avatar:hover {
  z-index: 1;
  transform: translateY(-0.5rem);
  cursor: pointer;
}
</style>
