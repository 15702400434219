export default {
  data() {
    return {
      msg: "Hello World",
    };
  },

  methods: {
    displayMessage: function (val) {
      if (val === null) return null;
      const hours = val.split(":")[0];
      const mins = val.split(":")[1];
      if (mins > 0) {
        return `${hours}h ${mins}m`;
      } else {
        return `${hours}h 0m`;
      }
    },
    padZero: function (num) {
      return num <= 9 ? "0" + num : num;
    },
    calculateTime: function (arr) {
      let hours = 0;
      let minutes = 0;
      let seconds = 0;
      for (let time in arr) {
        hours += parseInt(arr[time].substring(0, 2));
        minutes += parseInt(arr[time].substring(3, 5));
        seconds += parseInt(arr[time].substring(6));
        if (seconds > 59) {
          minutes += parseInt(seconds / 60);
          seconds = parseInt(seconds % 60);
        }
        if (minutes > 59) {
          hours += parseInt(minutes / 60);
          minutes = parseInt(minutes % 60);
        }
      }
      seconds = this.padZero(seconds);
      minutes = this.padZero(minutes);
      hours = this.padZero(hours);
      return `${hours}h ${minutes}m`;
    },
    parseDuration: function (duration) {
      if (duration) {
        const [hours, minutes] = duration
          ?.split(" ")
          .map((part) => parseInt(part));
        return hours * 60 + minutes;
      }
      return null;
    },
    formatDuration: function (minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}h ${remainingMinutes}m`;
    },
    calculateTimeDifference: function (duration1, duration2) {
      const totalMinutes1 = this.parseDuration(duration1);
      const totalMinutes2 = this.parseDuration(duration2);
      let difference;
      let diffType;
      if (totalMinutes1 > totalMinutes2) {
        difference = Math.abs(totalMinutes1 - totalMinutes2);
        diffType = "positive";
      } else {
        difference = Math.abs(totalMinutes2 - totalMinutes1);
        diffType = "negative";
      }
      // Ensure positive difference
      return { diff: this.formatDuration(difference), diffType };
    },
    zeroMin: function (stringDate) {
      let min = stringDate.split(" ")[1];
      if (min == "0m") {
        return stringDate.split(" ")[0];
      } else {
        return stringDate;
      }
    },
  },
};
