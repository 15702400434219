<template>
  <v-container fluid>
    <v-btn
      fab
      small
      slot
      v-if="$vuetify.breakpoint.mdAndDown"
      @click="openDatePicker"
      class="dateIcon"
      ><v-icon>mdi mdi-calendar-range</v-icon></v-btn
    >
    <v-row class="d-flex align-start pa-4">
      <v-col class="col-md-9">
        <v-row class="">
          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="blue"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects' }"
                  >
                    <v-icon dark> mdi mdi-file-document-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ projectsList.length }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >Total Project</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="#35CF96"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects', query: { status: '3' } }"
                  >
                    <v-icon dark>mdi mdi-check-circle-outline </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ completedProjects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >Completed Project</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="orange"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects', query: { status: '2' } }"
                  >
                    <v-icon dark>mdi mdi-clock-time-three-outline </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ inProgressProjects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >In Progress Project</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="#FA9978"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects', query: { status: '4' } }"
                  >
                    <v-icon dark>mdi mdi-hand-back-left-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ onHoldProjects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >On Hold</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="#F3425F"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects', query: { status: '5' } }"
                  >
                    <v-icon dark>mdi mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ cancelProjects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >Cancel</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pr-3">
          <v-col>
            <v-card
              tile
              class="pa-3"
              style="border-radius: 12px !important"
              flat
            >
              <v-card-title>Overdue Task</v-card-title>
              <v-divider></v-divider>
              <v-card-text
                class="d-flex flex-row overflow-x-auto align-stretch gap-2"
              >
                <div v-for="tasks in tasksList" :key="tasks.task_id">
                  <v-card
                    class="pa-3 ma-2 overflow-auto"
                    width="300px"
                    height="300px"
                  >
                    <v-card-subtitle @click="goToTask(tasks.task_project_id)">
                      <span class="cursor-progress"> {{ tasks.name }}</span>
                    </v-card-subtitle>
                    <v-card-text>
                      <ui class="more-assignees-menu">
                        <li class="asssignee-container mr-1">
                          <div
                            v-for="imageLink in tasks.assignees"
                            :key="imageLink.employee_id"
                          >
                            <v-avatar
                              v-if="imageLink?.clickup_profile_url"
                              class="white--text cursor-pointer text-uppercase fontsize-11 img-margin"
                              size="32"
                            >
                              <v-img
                                alt="John"
                                :src="imageLink?.clickup_profile_url"
                              ></v-img>
                            </v-avatar>
                            <v-avatar
                              size="32"
                              class="white--text cursor-pointer text-uppercase fontsize-11 img-margin"
                              v-else
                            >
                              {{ imageLink?.name?.split("")[0] }}
                              {{ imageLink?.name?.split("")[1] }}
                            </v-avatar>
                          </div>
                        </li>
                      </ui>
                      <v-sheet
                        v-if="tasks.due_date"
                        class="d-flex mb-1 align-center"
                        style="border-radius: 6px; height: 44px"
                        color="#F0F4F8"
                      >
                        <b class="ml-3 mr-1">Due Date : </b>
                        {{ formatDate(tasks.due_date) }}
                      </v-sheet>
                    </v-card-text>
                  </v-card>

                  <v-divider color="white"></v-divider>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pr-3">
          <v-col>
            <v-card
              tile
              class="pa-3"
              style="border-radius: 12px !important"
              flat
            >
              <v-card-title>Planned Task</v-card-title>
              <v-divider></v-divider>
              <v-card-text
                class="d-flex flex-row overflow-x-auto align-stretch gap-2"
              >
                <div v-for="tasks in plannedTasksList" :key="tasks.task_id">
                  <v-card
                    class="pa-3 ma-2 overflow-auto"
                    width="300px"
                    height="300px"
                  >
                    <v-card-subtitle @click="goToTask(tasks.task_project_id)">
                      <span class="cursor-progress">
                        {{ tasks.name }}
                      </span>
                    </v-card-subtitle>
                    <v-card-text>
                      <ui class="more-assignees-menu">
                        <li class="asssignee-container mr-1">
                          <div
                            v-for="imageLink in tasks.assignees"
                            :key="imageLink.employee_id"
                          >
                            <v-avatar
                              v-if="imageLink?.clickup_profile_url"
                              class="white--text cursor-pointer text-uppercase fontsize-11 img-margin"
                              size="32"
                            >
                              <v-img
                                alt="John"
                                :src="imageLink?.clickup_profile_url"
                              ></v-img>
                            </v-avatar>
                            <v-avatar
                              size="32"
                              class="white--text cursor-pointer text-uppercase fontsize-11 img-margin"
                              v-else
                            >
                              {{ imageLink?.name?.split("")[0] }}
                              {{ imageLink?.name?.split("")[1] }}
                            </v-avatar>
                          </div>
                        </li>
                      </ui>
                      <v-sheet
                        v-if="tasks.due_date"
                        class="d-flex mb-1 align-center"
                        style="border-radius: 6px; height: 44px"
                        color="#F0F4F8"
                      >
                        <b class="ml-3 mr-1">Due Date : </b>
                        {{ formatDate(tasks.due_date) }}
                      </v-sheet>
                    </v-card-text>
                  </v-card>

                  <v-divider color="white"></v-divider>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pr-3">
          <v-col>
            <v-card
              tile
              class="pa-3"
              style="border-radius: 12px !important"
              flat
            >
              <v-card-title>Project Due Dates</v-card-title>
              <v-divider />
              <v-card-text>
                <div v-for="project in projectsFilterList" :key="project.id">
                  <div class="d-flex align-center justify-between">
                    <div style="flex: 1">
                      <div
                        style="cursor: pointer"
                        @click="redirect(project.project_id)"
                      >
                        <Avatar
                          :size="'30px'"
                          :color="project.project_color_hex || 'blue lighten-3'"
                          :intials="
                            project.name
                              ? project.name.charAt(0).toUpperCase()
                              : 'P'
                          "
                          class="mr-2 my-2"
                        />
                        <span
                          style="font-size: 14px; font-weight: 400"
                          class="secondary--text"
                        >
                          {{ project.name }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span
                        style="font-size: 14px; font-weight: 400"
                        class="secondary--text"
                      >
                        {{ new Date(project.end_date).toDateString() }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-md-3" v-if="!$vuetify.breakpoint.smAndDown">
        <v-row no-gutters class="">
          <v-col cols="12">
            <v-card style="border-radius: 12px !important" elevation="0">
              <v-card-text style="height: 80vh">
                <v-date-picker
                  v-model="date"
                  :events="dueDate"
                  full-width
                  header
                  event-color="red"
                  color="secondary"
                  @input="onDateSelect"
                  scrollable
                >
                </v-date-picker>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :nudge-bottom="65"
    >
      <v-card style="border-radius: 12px !important" elevation="0">
        <v-card-text style="height: 80vh">
          <v-date-picker
            v-model="date"
            :events="dueDate"
            full-width
            header
            event-color="red"
            color="secondary"
            scrollable
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
import moment from "moment";
import Avatar from "./common/basic/Avatar.vue";
export default {
  data() {
    return {
      employees: "",
      projects: "",
      tasks: "",
      menu: false,
      itemsPerPage: 5,
      serverItems: [],
      loading: true,
      totalItems: 0,
      projectsList: [],
      projectsFilterList: [],
      tasksList: [],
      plannedTasksList: [],
      date: moment().format("YYYY-MM-DD"),
      dueDate: [],
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          width: "80%",
        },
        {
          text: "Due Date",
          value: "due_date",
        },
      ],
    };
  },
  components: {
    Avatar,
  },
  computed: {
    completedProjects() {
      return (
        this.projectsList.filter((p) => p.project_status === 3).length ?? 0
      );
    },

    inProgressProjects() {
      return (
        this.projectsList.filter((p) => p.project_status === 2).length ?? 0
      );
    },
    onHoldProjects() {
      return (
        this.projectsList.filter((p) => p.project_status === 4).length ?? 0
      );
    },
    cancelProjects() {
      return (
        this.projectsList.filter((p) => p.project_status === 5).length ?? 0
      );
    },
  },
  watch: {
    date() {
      if (this.date) {
        this.projectsFilterList = this.projectsList.filter(
          (project) =>
            moment(project.end_date).format("YYYY-MM-DD") === this.date
        );
      }
    },
  },
  methods: {
    redirect(project_id) {
      this.$router.push({ path: "/projects/" + project_id }).catch(() => {});
    },

    goToTask(task_project_id) {
      this.$router
        .push({
          path: `projects/${task_project_id}/board`,
        })
        .catch(() => {});
    },

    // fun() {
    //   return {
    //     color: "#fff",
    //     intials: "NULL",
    //   };
    // },

    openDatePicker() {
      this.menu = true;
    },
    getProject() {
      console.log("project function callled");
      let _self = this;
      _self.loading = true;
      this.$axios.get("/employee/project").then((res) => {
        _self.loading = false;
        let resdata = res.data;

        _self.projectsList = resdata.projects;
        this.FilterProjects(resdata.projects);
        this.filterProjectsByMonth();
        if (!resdata.success) {
          _self.snackbarError = true;
          _self.message = resdata.message;
        }
      });
    },

    onDateSelect(date) {
      if (!this.dueDate.includes(date)) {
        this.getProject();
      }
    },

    formatDate(date) {
      // Use moment to format the date
      return moment(date, "YYYY-MM-DD").format("MMMM DD, YYYY");
    },

    getDueDateTasksList() {
      console.log("task function callled");
      this.$axios.get("get_due_date_task").then((res) => {
        this.tasksList = res.data.taskListData;
        this.FilterTasks(this.tasksList);
      });
    },

    getPlannedTasksList() {
      console.log("task function callled");
      this.$axios.get("get_planned_task").then((res) => {
        this.plannedTasksList = res.data.taskListData;
      });
    },

    FilterProjects(projects) {
      this.dueDate = projects.map((project) =>
        moment(project.end_date).format("YYYY-MM-DD")
      );
      if (this.plannedTasksList) {
        this.dueDate.push(this.plannedTasksList[0].due_date.split("T")[0]);
      }
    },
    // FilterTasks(tasks) {
    //   console.log("test 436");
    //   // const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    //   this.dueDate = tasks.map((project) =>
    //     moment(project.dueDate).format("YYYY-MM-DD")
    //   );
    //   console.log(this.dueDate);
    // },
    filterProjectsByMonth() {
      const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
      this.projectsFilterList = this.projectsList.filter((project) =>
        moment(project.end_date).isBetween(
          startOfMonth,
          endOfMonth,
          "day",
          "[]"
        )
      );
    },
  },
  mounted() {
    // this.getDashboardCounts();
    this.getProject();
    this.getPlannedTasksList();
    this.getDueDateTasksList();
  },
};
</script>

<style scoped>
.cursor-progress {
  cursor: pointer;
}
</style>

<style>
/* .v-date-picker-header {
  display: none;
} */
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 10px !important;
}
.dateIcon {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 100;
}
v-container {
  position: relative;
}
.asssignee-container {
  position: relative !important;
  display: inline;
}
.img-margin {
  margin-left: -10px;
  border: 2px solid rgb(255, 255, 255);
  /* padding: 5px; */
  background-color: orangered;
}
</style>
